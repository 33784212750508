import { useMemo } from 'react';

import { ID } from '../../../../../types';
import { FiltersProps } from '../../../../../helpers/filters/hooks/useFilters';

import { useCategories } from '../../../hooks/useCategories';

import { AlertMessage } from '../../../../../helpers/AlertMessage';
import { MultiSelectFilter } from '../../../../../helpers/filters/MultiSelectFilter';

import { GET_CATEGORIES_QUERY } from '../../../pages/CategoriesIndexPage';

interface CategoriesFilterProps extends FiltersProps<ID[]> {
  i18nPlaceholder?: string;
  scope: string[];
}

function CategoriesFilter({
  i18nPlaceholder,
  name,
  onChange,
  scope,
  value
}: CategoriesFilterProps) {
  const { categories, categoriesError, categoriesLoading } = useCategories({
    cacheKey: scope.join(),
    query: GET_CATEGORIES_QUERY,
    initialFilters: { scope },
    initialLimit: 1000
  });

  const data = useMemo(() => {
    return categories.map(({ id: value, name: label }) => ({ value, label }));
  }, [categories]);

  return (
    <>
      <MultiSelectFilter
        data={data}
        i18nPlaceholder={i18nPlaceholder}
        name={name}
        onChange={onChange}
        optionsLoading={categoriesLoading}
        value={value}
      />
      <AlertMessage addClassName="mt-2" message={categoriesError} />
    </>
  );
}

export default CategoriesFilter;

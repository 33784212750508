import find from 'lodash/find';
import head from 'lodash/head';
import last from 'lodash/last';

import {
  SmartContractID,
  SmartContractStatus,
  SmartContractStatuses
} from '../../smartContractsTypes';

interface GetDefaultSmartContractSmartContract {
  id: SmartContractID;
  status: SmartContractStatus;
}

function getDefaultSmartContract<
  SmartContractType extends GetDefaultSmartContractSmartContract
>(
  smartContracts: SmartContractType[],
  pickSmartContract: 'first' | 'last' = 'first'
): SmartContractType {
  return (
    find(
      smartContracts,
      (smartContract) => smartContract.status === SmartContractStatuses.OPEN
    ) ||
    (pickSmartContract === 'first'
      ? head(smartContracts)
      : last(smartContracts))
  );
}

export default getDefaultSmartContract;
